<template>
  <div v-if="questionsData && questionsData.length>0" class="sub-ques">
    <div v-for="(item,index) in questionsData" :key="index" class="sub-item">
      <single-choice-question
        v-show="item.type==102001"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <multiple-choice-question
        v-show="item.type==102002"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <text-question
        v-if="item.type==102003"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <picture-question
        v-if="item.type==102004"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <text-and-picture-question
        v-if="item.type==102005"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <vas5-question
        v-if="item.type==102006 && item.vasScore===5"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <vas10-question
        v-if="item.type==102006 && item.vasScore===10"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <survey-question
        v-if="item.type==102007"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
      <nps-question
        v-if="item.type==102008"
        :question-data="item"
        :question-index="index"
        :question-total="questionsData.length"
        :is-additional="true"
        :is-editable="isEditable"
        :after-answered="_afterAnswered"
        :is-preview="isPreview"
      />
    </div>
  </div>
</template>
<script>

import SingleChoiceQuestion from './SingleChoiceQuestion'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import TextQuestion from './TextQuestion'
import TextAndPictureQuestion from './TextAndPictureQuestion'
import Vas10Question from './Vas10Question'
import Vas5Question from './Vas5Question'
import SurveyQuestion from './SurveyQuestion'
import NpsQuestion from './NpsQuestion'
import PictureQuestion from './PictureQuestion'

export default {
  name: 'AdditionalQuestions',
  components: {
    SingleChoiceQuestion,
    MultipleChoiceQuestion,
    TextQuestion,
    TextAndPictureQuestion,
    Vas10Question,
    Vas5Question,
    SurveyQuestion,
    NpsQuestion,
    PictureQuestion
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    masterQuestionData: Object,
    questionsData: Array,
    afterAnswered: Function
  },

  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    // this.isEditable = false
  },
  methods: {
    _afterAnswered() {
      this.afterAnswered(this.masterQuestionData)
    }
  }

}
</script>
<style lang="scss">
 .sub-ques{
    background: #F5F6FA;
    margin-left: 0.8rem;
    margin-bottom: 0.65rem;
    border-radius: 0.25rem;
   .sub-item{
     padding: 0.5rem;
   }
   .sub-title{
     font-size:.6rem;
   }
   .custom-filed{
     background: #eee;
     padding:0.05rem;
     font-size:.6rem;
     color:#666666;
     border-radius: 0.05rem;
     margin-bottom: 0.5rem;
   }
 }
</style>
